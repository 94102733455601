import { createSelector } from "reselect"
import moment from "moment"

const REQUEST = "sensor/face/today/REQUEST"
const FULLFILLED = "sensor/face/today/FULLFILLED"
const REJECTED = "sensor/face/today/REJECTED"
const ADD = "sensor/face/today/ADD"

const getData = ({ sensorId, from = moment().startOf("day").format() }) => {
  const path = `/api/1.0/facedashboard/${sensorId}/query?from=${from}&detail=true&orderby=-1`
  return fetch(path).then((res) => res.json())
}

const getState = (state) => state.sensor.face.today

export const selectors = {
  isPending: createSelector(getState, (substate) => substate.isPending),
  isFulfilled: createSelector(getState, (state) => state.isFulfilled),
  isRejected: createSelector(getState, (state) => state.isRejected),
  data: createSelector(getState, (state) => state.data),
}

export const getToday = (sensorId) => (dispatch) => {
  dispatch(request())
  return getData({
    sensorId,
  })
    .then((data) => {
      dispatch(fullfilled(data))
    })
    .catch((err) => {
      dispatch(rejected(err))
    })
}

export const request = () => ({
  type: REQUEST,
})

export const fullfilled = (data) => ({
  type: FULLFILLED,
  payload: data,
})
export const rejected = (err) => ({
  type: REJECTED,
  paylaod: err,
})

export const add = (newData) => ({
  type: ADD,
  payload: newData,
})

export const actions = {
  getToday,
  add,
}

export function reducer(
  state = {
    err: null,
    data: null,
    isPending: false,
    isFulfilled: false,
    isRejected: false,
  },
  action
) {
  switch (action.type) {
    case REQUEST:
      return {
        ...state,
        err: null,
        isPending: true,
        isRejected: false,
        isFulfilled: false,
      }
    case FULLFILLED:
      return {
        ...state,
        isPending: false,
        isFulfilled: true,
        isRejected: false,
        data: action.payload,
      }
    case REJECTED:
      return {
        ...state,
        isPending: false,
        isFulfilled: false,
        isRejected: true,
        err: action.payload,
      }
    case ADD:
      return {
        ...state,
        data: {
          ...state.data,
          data: action.payload.concat(state.data.data),
        },
      }

    default:
      return state
  }
}

export default reducer
