import { createCollection } from "../../lib/redux-async/task"
import { attr, fk } from "redux-orm"
import axios from "axios"
import orm from "../orm"

const manipulate = (data) => {
  data.iotSensor = data.accessKey
  return data
}
const Api = {
  show(id) {
    return axios
      .get(`/api/1.0/camera/${id}`)
      .then((res) => res.data)
      .then(manipulate)
  },

  index() {
    return axios.get("/api/1.0/camera").then((res) => res.data.map(manipulate))
  },
}

const collection = createCollection("sensor/model", Api, orm, {
  modelCb: (Model) => {
    Model.modelName = "Sensor"
    Model.fields = {
      _id: attr(),
      accessKey: attr(),
      iotSensor: fk("IotSensor", "sensor"),
    }
    Model.options = {
      idAttribute: "_id",
    }
  },
})

export const actions = collection.actions
export const reducer = collection.reducer
export const selectors = collection.selectors
export const model = collection.model
export const saga = collection.saga

export default reducer
