import { createActions, handleActions } from "redux-actions"
import { Model } from "redux-orm"
import { call, takeLatest, takeEvery, all, put } from "redux-saga/effects"
import inflection from "inflection"
import { createSelector } from "reselect"

const bypass = (payload) => payload
const byId = (id, value) => ({ id, value })

/***
 * Object를 입력 받아서 Object에 namespace를 추가한다.
 *
 * @param namespace Object로 만들 namespace. /로 구분한다.
 * @param travel namespace된 Object가 삽입될 Object
 * @param options
 * @param callback leaf object를 인자로 받는 콜백 리턴 값은 leaf object에 할당된다.

 * @return leaf object
 *
 */
const namespaceMap = (namespace, travel = {}, options = {}, callback = null) => {
  return namespace.split("/").reduce((acc, cur, idx, namespaces) => {
    if (options.lowerCase) cur = cur.toLowerCase()
    if (options.camelize) cur = inflection.camelize(cur, true)
    if (idx == namespaces.length - 1 && callback) {
      const ret = callback(acc[cur])
      if (ret != undefined) acc[cur] = ret
    } else if (!acc[cur]) acc[cur] = {}

    return acc[cur]
  }, travel)
}

/**
 * state의 특정 SubObject 에 접근할 할 때 사용할 수 있다. entry()를 이용해서
 * 접근하면 undefined된 Object라도 에러 없이 접근이 가능하다.
 * @param defaultState 존재하지 않는 SubObject를 초기화할 기본값
 */
export const createEntry = (defaultState = {}) => {
  /**
   * @param state
   * @param id state 하위 오브젝트의 키(eg. state[id])
   * @param callback state[id] 오브젝트를 인자로 가지는 callback이다. callback은 state[id]에 overwrite할 오브젝트를 반환해야 한다.
   */
  const entry = (state, id, callback) => {
    let entry = {
      ...defaultState,
    }
    if (state && state[id]) {
      entry = {
        ...entry,
        ...state[id],
      }
    }
    if (callback) {
      entry = {
        ...entry,
        ...callback(entry),
      }
    }
    return entry
  }
  return entry
}

/**
 * @param namespcae string 생성하려는 namespace ex) camera/show
 * @param name string 생성하려는 task의 이름
 * @param asyncFunc function 비동기 함수 value를 인자로 받는다.
 * @param options.ignoreData bool Store에 data필드를 추가하지 않는다.
 *
 * @return reducer, actions, saga, selectors를 반환한다.
 */
export const createTask = (namespace, name, asyncFunc, options = {}) => {
  const _actions = {}
  namespaceMap(namespace, _actions, {}, () => ({
    [name]: {
      REQUEST: bypass,
      SUCCESS: bypass,
      FAIL: bypass,
      DONE: bypass,
    },
  }))
  const actions = namespaceMap(namespace, createActions(_actions), {
    camelize: true,
    lowerCase: true,
  })

  const _reducer = {
    [actions[name].request]: (state, { payload }) => ({
      ...state,
      [name]: {
        ...state[name],
        error: null,
        isPending: true,
        isFulfilled: false,
        isRejected: false,
        isInitial: false,
      },
    }),
    [actions[name].done]: (state) => ({
      ...state,
      [name]: {
        ...state[name],
        isPending: false,
      },
    }),
    [actions[name].success]: (state, { payload }) => ({
      ...state,
      [name]: {
        ...state[name],
        data: options.ignoreData ? null : payload,
        isFulfilled: true,
      },
    }),
    [actions[name].fail]: (state, { payload }) => ({
      ...state,
      [name]: {
        ...state[name],
        error: payload,
        isRejected: true,
      },
    }),
  }

  const reducer = handleActions(_reducer, {})
  const entry = createEntry({
    error: null,
    isInitial: true,
    isFulfilled: false,
    isRejected: false,
    isPending: false,
  })
  const getState = (state) => entry(namespaceMap(namespace, state), name)
  const selectors = {
    [name]: {
      isInitial: createSelector(getState, (state) => () => state.isInitial),
      isFulfilled: createSelector(getState, (state) => () => state.isFulfilled),
      isRejected: createSelector(getState, (state) => () => state.isRejected),
      isPending: createSelector(getState, (state) => () => state.isPending),
      error: createSelector(getState, (state) => () => state.error),
      data: createSelector(getState, (state) => () => state.data),
    },
  }
  selectors[name].isSettled = createSelector(
    selectors[name].isFulfilled,
    selectors[name].isRejected,
    (isFulfilled, isRejected) => () => isFulfilled() || isRejected()
  )

  function* handleAction(action) {
    const { payload } = action
    try {
      const data = yield call(asyncFunc, payload)

      yield put(actions[name].success(data))
    } catch (error) {
      error.message = `${namespace}/${name} ${error.message}`
      if (error.response) {
        try {
          error.message = `${error.message} - ${error.config.url} ${JSON.stringify(
            error.response.data,
            null,
            2
          )}`
        } catch (error) {
          error.message += ` - ${error.config.url} ${error.response.data}`
        }
      }
      yield put(actions[name].fail(error))
    }
    yield put(actions[name].done())
  }

  function* rootSaga() {
    yield takeLatest(`${actions[name].request}`, handleAction)
  }

  return {
    reducer,
    actions,
    saga: rootSaga,
    selectors,
  }
}

/**
 * @param namespace 생성하려는 namespace
 * @param name 생성하려는 task이름
 * @param asyncFunc 비동기 함수 id, value를 인자로 받는다.
 *
 * @return reducer, actions, saga, selectors를 반환한다.
 */
export const createIdTask = (namespace, name, asyncFunc, options = {}) => {
  const entry = createEntry({
    error: null,
    isInitial: true,
    isFulfilled: false,
    isRejected: false,
    isPending: false,
  })
  const _actions = {}
  namespaceMap(namespace, _actions, {}, () => ({
    [name]: {
      REQUEST: byId,
      SUCCESS: byId,
      FAIL: byId,
      DONE: bypass,
    },
  }))
  const actions = namespaceMap(namespace, createActions(_actions), {
    camelize: true,
    lowerCase: true,
  })
  const _reducer = {
    [actions[name].request]: (state, { payload }) => ({
      ...state,
      [name]: {
        ...state[name],
        [payload.id]: entry(state[name], payload.id, (item) => ({
          ...item,
          isFulfilled: false,
          isRejected: false,
          isPending: true,
          isInitial: false,
          error: null,
        })),
      },
    }),
    [actions[name].done]: (state, { payload }) => ({
      ...state,
      [name]: {
        ...state[name],
        [payload.id]: entry(state[name], payload.id, (item) => {
          return {
            ...item,
            isPending: false,
          }
        }),
      },
    }),
    [actions[name].success]: (state, { payload }) => ({
      ...state,
      [name]: {
        ...state[name],
        [payload.id]: entry(state[name], payload.id, (item) => ({
          ...item,
          data: options.ignoreData ? null : payload.value,
          isFulfilled: true,
        })),
      },
    }),
    [actions[name].fail]: (state, { payload }) => ({
      ...state,
      [name]: {
        ...state[name],
        [payload.id]: entry(state[name], payload.id, (item) => ({
          ...item,
          error: payload.value,
          isRejected: true,
        })),
      },
    }),
  }
  const reducer = handleActions(_reducer, {})
  const _entry = createEntry({})
  const getState = (state) => _entry(namespaceMap(namespace, state), name)

  const selectors = {
    [name]: {
      isInitial: createSelector(getState, (state) => (id) => entry(state, id).isInitial),
      isFulfilled: createSelector(getState, (state) => (id) => entry(state, id).isFulfilled),
      isRejected: createSelector(getState, (state) => (id) => entry(state, id).isRejected),
      isPending: createSelector(getState, (state) => (id) => entry(state, id).isPending),
      error: createSelector(getState, (state) => (id) => entry(state, id).error),
      data: createSelector(getState, (state) => (id) => entry(state, id).data),
    },
  }
  selectors[name].isSettled = createSelector(
    selectors[name].isFulfilled,
    selectors[name].isRejected,
    (isFulfilled, isRejected) => (id) => isFulfilled(id) || isRejected(id)
  )

  function* handleAction(action) {
    const { payload } = action
    const { id, value } = payload
    try {
      const data = yield call(asyncFunc, id, value)
      yield put(actions[name].success(id, data))
    } catch (error) {
      error.message = `${namespace}/${name} ${error.message}`
      if (error.response) {
        try {
          error.message = `${error.message} - ${error.config.url} ${JSON.stringify(
            error.response.data,
            null,
            2
          )}`
        } catch (error) {
          error.message += ` - ${error.config.url} ${error.response.data}`
        }
      }
      yield put(actions[name].fail(id, error))
    }
    yield put(actions[name].done(payload))
  }

  function* rootSaga() {
    yield takeEvery(`${actions[name].request}`, handleAction)
  }

  return {
    actions,
    reducer,
    selectors,
    saga: rootSaga,
  }
}

// Create generic collection for redux, redux-saga, redux-orm.
// this function create actions, selectors, model, reducer
export const createCollection = (
  namespace,
  apiSet,
  orm,
  options = {
    modelCb: (model) => {},
    modelReducer: (action, Model, session) => {},
  }
) => {
  if (!apiSet) console.warn(namespace)
  const show = createIdTask(namespace, "show", apiSet.show, {
    ignoreData: true,
  })
  const create = createIdTask(namespace, "create", apiSet.create, {
    ignoreData: true,
  })
  const destroy = createIdTask(namespace, "destroy", apiSet.destroy, {
    ignoreData: true,
  })
  const update = createIdTask(namespace, "update", apiSet.update, {
    ignoreData: true,
  })
  const index = createTask(namespace, "index", apiSet.index, { ignoreData: true })

  const actions = {
    ...show.actions,
    ...create.actions,
    ...destroy.actions,
    ...index.actions,
    ...update.actions,
  }

  const reducer = (state, action) => {
    return [index, show, create, update, destroy].reduce(
      (state, item) => item.reducer(state, action),
      state
    )
  }

  const selectors = {
    ...show.selectors,
    ...index.selectors,
    ...create.selectors,
    ...destroy.selectors,
    ...update.selectors,
  }

  function* saga() {
    yield all([show.saga(), index.saga(), create.saga(), destroy.saga(), update.saga()])
  }

  const ret = {
    actions,
    reducer,
    saga,
    selectors,
  }

  if (orm) {
    class _Model extends Model {
      static reducer(action, _Model, session) {
        switch (action.type) {
          case `${show.actions.show.success}`:
            _Model.upsert(action.payload.value)
            break
          case `${create.actions.create.success}`:
            _Model.create(action.payload.value)
            break
          case `${index.actions.index.success}`:
            action.payload.forEach((p) => _Model.upsert(p))
            break
          case `${destroy.actions.destroy.success}`:
            _Model.withId(action.payload.id).delete()
            break
        }
        if (options.modelReducer) return options.modelReducer(action, _Model, session)
        else return session.state
      }
    }
    if (options.modelCb) options.modelCb(_Model)

    const dbStateSelector = (state) => state.db
    selectors.model = createSelector(dbStateSelector, (db) => {
      const session = orm.session(db)

      return session[_Model.modelName]
    })
    selectors.data = createSelector(selectors.model, (model) => model.all().toModelArray())

    ret.model = _Model
  }

  return ret
}

export default createCollection
