import { combineReducers } from "redux"
import * as Last from "./last"
import * as Today from "./today"

export const reducer = combineReducers({
  last: Last.reducer,
  today: Today.reducer,
})

export default reducer
