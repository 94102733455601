import { createActions, handleActions, combineActions } from "redux-actions"
import { createTask } from "../../lib/redux-async"
import { BrowserTokenModel } from "common-library/token-model"
import axios from "axios"
import { createSelector } from "reselect"
import * as jose from "jose"
import { fork, takeEvery, put } from "redux-saga/effects"

const tokenModel = new BrowserTokenModel()

const Renew = createTask(
  "auth/token",
  "renew",
  () =>
    axios
      .get("/auth/local/renew")
      .then((res) => res.data)
      .then((data) => {
        tokenModel.setToken(data.token)
        return data.token
      }),
  { ignoreData: true }
)

export const _actions = createActions({
  auth: {
    token: {
      GET: () => tokenModel.getToken(),
      SET: (token) => token,
    },
  },
}).auth.token

const initialState = () => {
  const token = tokenModel.getToken()
  if (token) {
    return {
      token,
      decoded: jose.decodeJwt(token),
    }
  } else {
    return {}
  }
}

export const _reducer = handleActions(
  {
    [combineActions(_actions.set, _actions.get)]: (state, { payload }) => ({
      ...state,
      token: payload,
      decoded: jose.decodeJwt(payload),
    }),
  },
  initialState()
)

export const actions = {
  ..._actions,
  ...Renew.actions,
}

export const reducer = (state = initialState(), action) => {
  state = _reducer(state, action)
  state = Renew.reducer(state, action)

  return state
}

const getState = (state) => state.auth.token
export const selectors = {
  ...Renew.selectors,
  value: createSelector(getState, (state) => state.token),
  decoded: createSelector(getState, (state) => state.decoded),
}

function* updateToken(action) {
  yield put(actions.set(action.payload))
}

export function* saga() {
  yield fork(Renew.saga)
  yield takeEvery(`${actions.renew.success}`, updateToken)
}
