import { createCollection } from "../../../lib/redux-async/task"
import { attr } from "redux-orm"
import axios from "axios"
import orm from "../../orm"

const Api = {
  show(id) {
    return axios.get(`/api/1.0/iot/device/${id}`).then((res) => res.data.data)
  },
  index() {
    return axios.get("/api/1.0/iot/device").then((res) => res.data.data)
  },
}

const collection = createCollection("iot/sensor/model", Api, orm, {
  modelCb: (Model) => {
    Model.modelName = "IotSensor"
    Model.fields = {
      uid: attr(),
    }
    Model.options = {
      idAttribute: "uid",
    }
  },
})

export const actions = collection.actions
export const reducer = collection.reducer
export const selectors = collection.selectors
export const model = collection.model
export const saga = collection.saga

export default reducer
