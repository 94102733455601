import awsLogo from "./logo/aws.png"
import msLogo from "./logo/ms.png"
import faceLogo from "./logo/faceplusplus.png"

const engineList = {
  ms: {
    name: "Microsoft Azure",
    logo: msLogo,
    order: 1,
  },
  "face++": {
    name: "Face++",
    logo: faceLogo,
    order: 3,
  },
  awsface: {
    name: "Rekognition",
    logo: awsLogo,
    order: 2,
  },
  edge: {
    name: "Edge",
    logo: null,
    order: 4,
  },
}

const sortEngine = (a, b) => {
  return engineList[a.engine].order - engineList[b.engine].order
}

export { engineList, sortEngine }
