import { createStore, applyMiddleware, compose } from "redux"
import thunk from "redux-thunk"
import { createLogger } from "redux-logger"
import { routerMiddleware } from "react-router-redux"
import createSagaMiddleware from "redux-saga"

import rootReducer, { rootSaga } from "./modules"
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const createConfiguredStore = (rootReducer) => {
  const reduxRouterMiddleware = routerMiddleware(history)
  const sagaMiddleware = createSagaMiddleware()
  const middlewares = [thunk]
  if (process.env.NODE_ENV == "development") middlewares.push(createLogger())
  middlewares.push(...[reduxRouterMiddleware, thunk, sagaMiddleware])
  const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middlewares)))
  sagaMiddleware.run(rootSaga)

  return store
}

export const store = createConfiguredStore(rootReducer)
module.hot &&
  module.hot.accept("./modules", () => {
    store.replaceReducer(require("./modules").default)
  })

export default store
