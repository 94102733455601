import { combineReducers } from "redux"
import { routerReducer } from "react-router-redux"
import { createReducer as createDbReducer } from "redux-orm"
import { all } from "redux-saga/effects"
import orm from "./orm"

import * as Sensor from "./sensor"
import * as Auth from "./auth"
import * as Account from "./account"
import * as Iot from "./iot"
import * as Company from "./company"
import * as Store from "./store"
export const models = [Sensor.model, Account.model, Iot.model, Company.model, Store.model]
orm.register(...models)

export const reducer = combineReducers({
  routing: routerReducer,
  db: createDbReducer(orm),
  sensor: Sensor.reducer,
  auth: Auth.reducer,
  account: Account.reducer,
  iot: Iot.reducer,
  company: Company.reducer,
  store: Store.reducer,
})

export function* rootSaga() {
  try {
    yield all([
      Sensor.saga(),
      Account.saga(),
      Auth.saga(),
      Iot.saga(),
      Company.saga(),
      Store.saga(),
    ])
  } catch (err) {
    console.error(err)
  }
}

export default reducer
