import { combineReducers } from "redux"
import * as Model from "./model"
import { fork } from "redux-saga/effects"

export const reducer = combineReducers({ model: Model.reducer })
export const model = Model.model
export function* saga() {
  yield fork(Model.saga)
}

export default reducer
