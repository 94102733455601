import { createSelector } from "reselect"

const REQUEST = "sensor/face/last/REQUEST"
const FULLFILLED = "sensor/face/last/FULLFILLED"
const REJECTED = "sensor/face/last/REJECTED"
const ADD = "sensor/face/last/ADD"
const SET_MAX_COUNT = "sensor/face/last/SET_MAX_COUNT"

const getData = ({ sensorId, nmemb }) => {
  const path = `/api/1.0/facedashboard/${sensorId}/last/${nmemb}?detail=true`
  return fetch(path).then((res) => res.json())
}

const getState = (state) => state.sensor.face.last

export const selectors = {
  isPending: createSelector(getState, (substate) => substate.isPending),
  isFulfilled: createSelector(getState, (state) => state.isFulfilled),
  isRejected: createSelector(getState, (state) => state.isRejected),
  data: createSelector(getState, (state) => state.data),
}

export const getLastN = (sensorId, nmemb) => (dispatch) => {
  dispatch(request())
  return getData({
    sensorId,
    nmemb,
  })
    .then((data) => {
      dispatch(setMaxCount(nmemb))
      dispatch(fullfilled(data))
    })
    .catch((err) => {
      dispatch(rejected(err))
    })
}

export const request = () => ({
  type: REQUEST,
})

export const fullfilled = (data) => ({
  type: FULLFILLED,
  payload: data,
})
export const rejected = (err) => ({
  type: REJECTED,
  paylaod: err,
})

export const add = (newData) => ({
  type: ADD,
  payload: newData,
})

export const setMaxCount = (count) => ({
  type: SET_MAX_COUNT,
  payload: count,
})

export const actions = {
  getLastN,
  add,
  setMaxCount,
}

export function reducer(
  state = {
    maxCount: 100,
    err: null,
    data: null,
    isPending: false,
    isFulfilled: false,
    isRejected: false,
  },
  action
) {
  switch (action.type) {
    case REQUEST:
      return {
        ...state,
        err: null,
        isPending: true,
        isRejected: false,
        isFulfilled: false,
      }
    case FULLFILLED:
      return {
        ...state,
        isPending: false,
        isFulfilled: true,
        isRejected: false,
        data: action.payload,
      }
    case REJECTED:
      return {
        ...state,
        isPending: false,
        isFulfilled: false,
        isRejected: true,
        err: action.payload,
      }
    case SET_MAX_COUNT:
      return {
        ...state,
        maxCount: action.payload,
      }
    case ADD:
      return {
        ...state,
        data: {
          ...state.data,
          data: action.payload.concat(state.data.data).slice(0, state.maxCount),
        },
      }

    default:
      return state
  }
}

export default reducer
