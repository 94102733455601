import { createCollection } from "../../lib/redux-async"
import orm from "../orm"
import axios from "axios"

const Api = {
  index() {
    return axios.get("/api/1.0/account")
  },

  show(id) {
    return axios.get(`/api/1.0/account/${id}`)
  },
}

export const { actions, selectors, reducer, saga, model } = createCollection(
  "account/model",
  Api,
  orm,
  {
    modelCb: (Model) => {
      Model.modelName = "Account"
      Model.options = { idAttribute: "_id" }

      return Model
    },
  }
)
