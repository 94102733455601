import * as Model from "./model"
import { combineReducers } from "redux"
import { fork } from "redux-saga/effects"

export const reducer = combineReducers({
  model: Model.reducer,
})

export function* saga() {
  yield fork(Model.saga)
}

export const model = Model.model
