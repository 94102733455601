import { createCollection } from "../../lib/redux-async"
import axios from "axios"
import orm from "../orm"
import { attr, fk } from "redux-orm"

const Api = {
  index: () =>
    axios("/api/1.0/store").then((res) =>
      res.data.map((d) => {
        d.company = d.companyId
        return d
      })
    ),
  show: (id) =>
    axios(`/api/1.0/store/${id}`).then((res) =>
      res.data.then((d) => {
        d.company = d.companyId
        return d
      })
    ),
  create: () => axios("/api/1.0/store/${id}").then((res) => res.data),
  update: () => axios("/api/1.0/store/${id}").then((res) => res.data),
  destroy: (id) => axios(`/api/1.0/store/${id}`).then((res) => res.data),
}

const collection = createCollection("store/model", Api, orm, {
  modelCb: (Store) => {
    Store.modelName = "Store"
    Store.options = {
      idAttribute: "_id",
    }
    Store.fields = {
      _id: attr(),
      name: attr(),
      company: fk("Company"),
    }
  },
})

export const actions = collection.actions
export const reducer = collection.reducer
export const selectors = collection.selectors
export const saga = collection.saga
export const model = collection.model
