import * as Model from "./model"
import * as Face from "./face"
import { fork } from "redux-saga/effects"
import { combineReducers } from "redux"

export const model = Model.model
export const reducer = combineReducers({
  face: Face.reducer,
  model: Model.reducer,
})

export function* saga() {
  yield fork(Model.saga)
}
export default reducer
