"use strict"

const strings = {
  xs: "(max-width: 767px)",
  sm: "(min-width: 768px) and (max-width: 991px)",
  md: "(min-width: 992px) and (max-width: 1199px)",
  lg: "(min-width: 1200px)",
}

export default {
  strings,
  queries: {
    xs: window.matchMedia(strings.xs),
    sm: window.matchMedia(strings.sm),
    md: window.matchMedia(strings.md),
    lg: window.matchMedia(strings.lg),
  },
}
