import { createCollection } from "../../lib/redux-async"
import axios from "axios"
import orm from "../orm"
import { attr } from "redux-orm"

const Api = {
  index: () => axios("/api/1.0/company").then((res) => res.data),
  show: (id) => axios(`/api/1.0/company/${id}`).then((res) => res.data),
  create: () => axios("/api/1.0/company/${id}").then((res) => res.data),
  update: () => axios("/api/1.0/company/${id}").then((res) => res.data),
  destroy: (id) => axios(`/api/1.0/company/${id}`).then((res) => res.data),
}

const collection = createCollection("company/model", Api, orm, {
  modelCb: (Company) => {
    Company.modelName = "Company"
    Company.options = {
      idAttribute: "_id",
    }
    Company.fields = {
      _id: attr(),
      name: attr(),
    }
  },
})

export const actions = collection.actions
export const reducer = collection.reducer
export const selectors = collection.selectors
export const saga = collection.saga
export const model = collection.model
