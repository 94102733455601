import * as Model from "./sensor/model"
import { fork } from "redux-saga/effects"
import { combineReducers } from "redux"

export const model = Model.model
export const reducer = combineReducers({
  sensor: combineReducers({
    model: Model.reducer,
  }),
})

export function* saga() {
  yield fork(Model.saga)
}

export default reducer
