export { createCollection, createTask, createIdTask, createEntry } from "./task"
import propTypes from "prop-types"
import React, { Component } from "react"

export const createAsync = (Components) => {
  class Async extends Component {
    constructor(props) {
      super(props)
      this.LastComponent = Components.Initial

      const { actionFn } = this.props
      if (actionFn) actionFn()
    }

    render() {
      const props = this.props

      if (props.isInitial && Components.Initial) {
        this.LastComponent = Components.Initial
      } else if (props.isSettled && Components.Settled) {
        this.LastComponent = Components.Settled
      } else if (props.isRejected && Components.Rejected) {
        this.LastComponent = Components.Rejected
      } else if (props.isPending && Components.Pending) {
        this.LastComponent = Components.Pending
      } else if (props.isFulfilled && Components.Fulfilled) {
        this.LastComponent = Components.Fulfilled
      }
      if (!this.LastComponent) return null
      return <this.LastComponent {...props} />
    }
  }
  Async.propTypes = {
    actionFn: propTypes.func,
    isInitial: propTypes.bool,
    isSettled: propTypes.bool,
    isRejected: propTypes.bool,
    isPending: propTypes.bool,
    isFulfilled: propTypes.bool,
  }

  return Async
}
