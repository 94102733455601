import * as Token from "./token"
import { combineReducers } from "redux"
import { fork } from "redux-saga/effects"

export const reducer = combineReducers({
  token: Token.reducer,
})

export function* saga() {
  yield fork(Token.saga)
}
